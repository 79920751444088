import React from "react";
import "../bulma.scss";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeroSection from "../components/HeroSection";
import CTABanner from "../components/CtaBanner";
import { useStaticQuery, graphql } from "gatsby";

const AboutPage = () => {
  const { workingImg, workingImg2, handsImg, peopleImg } = useStaticQuery(
    graphql`
      query {
        workingImg: file(relativePath: { eq: "cover_sobre_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 720, quality: 100, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        workingImg2: file(relativePath: { eq: "img-sobre-1_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 720, quality: 100, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        handsImg: file(relativePath: { eq: "img-sobre-2_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 720, quality: 100, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        peopleImg: file(relativePath: { eq: "img-sobre-3_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 720, quality: 100, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const esxTheme = {
    main: "var(--esx-red)",
    color: "var(--esx-red)",
    gradient: "#fafafa",
    titleColor: "var(--esx-red)",
    textColor: "var(--esx-black)",
  };
  const esxThemeRed = {
    main: "#fafafa",
    color: "#fafafa",
    gradient: "linear-gradient(var(--esx-red), var(--esx-red))",
    titleColor: "#fff",
    textColor: "#fafafa",
  };
  return (
    <Layout>
      <SEO title="Sobre nós" />

      <HeroSection
        theme={esxThemeRed}
        title="Sobre nós"
        text="Com foco em pessoas e tecnologia, ajudamos empresas a melhorarem realidades e gerarem novas vantagens competitivas.<br/><br/>
        São mais de 8 anos de história, 300 projetos realizados e 100 clientes atendidos no Brasil e no exterior.<br/><br/>
        Em 2010 fomos a primeira organização do mundo a conquistar nível gold em ALM e em 2012 recebemos o prêmio de melhor parceira global nesta competência pela Microsoft.
        "
        fixedImage={workingImg.childImageSharp.fluid}
      />
      <HeroSection
        theme={esxTheme}
        invert
        title="Só tecnologia não basta"
        text="A tecnologia é importante e capaz de provocar mudanças de grande impacto, mas ela é apenas o instrumento que utilizamos.<br/><br/>  
        Entendemos que as decisões de negócios não podem ser pautadas apenas pela aquisição e distribuição de tecnologias, são necessárias ações verdadeiramente centradas em pessoas.<br/><br/>
        E a reação natural dessas ações é o valor real que geramos para nossos clientes, e para os clientes deles, de maneira genuína e sustentável.
        "
        fixedImage={workingImg2.childImageSharp.fluid}
      />
      <CTABanner
        title="Que tal fazer parte da equipe ESX?"
        subtitle="Uma empresa dedicada com o seu desenvolvimento pessoal e profissional"
        buttonText="Quero trabalhar na ESX"
        buttonLink="/jobs"
      />
      <HeroSection
        theme={esxTheme}
        title="O que fazemos então?"
        text="Trabalhamos ouvindo nossos clientes e criando soluções tecnologicamente viáveis para cada contexto específico, sendo pragmáticos para resolver suas principais dores com agilidade.<br/><br/>

        Posteriormente, caso seja necessário, trabalhamos progressivamente em mudanças estruturais e culturais mais profundas.<br/><br/>
        
        Dessa forma, nossos clientes estarão preparados para se adaptarem as novas realidades, gerarem novas vantagens competitivas e melhorarem as já existentes.
        
  "
        fixedImage={handsImg.childImageSharp.fluid}
      />
      <HeroSection
        invert
        theme={esxTheme}
        title="O que a nossa cultura tem a ver com isso tudo?"
        text="As pessoas da ESX levam a nossa cultura para dentro de nossos clientes.
        Nossas equipes trabalham de forma colaborativa e possuem autonomia para questionar, validar hipóteses e tomar decisões.<br/><br/>
        Criamos um espaço propício ao desenvolvimento, com incentivos, desafios e oportunidades de crescimento profissional.
        Um ambiente onde as pessoas têm segurança e suporte para desenvolverem ao máximo suas habilidades e entregarem valor continuamente.
  "
        fixedImage={peopleImg.childImageSharp.fluid}
        buttonLink="/cultura"
        buttonText="Nossa cultura"
      />
    </Layout>
  );
};

export default AboutPage;
